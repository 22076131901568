import React from 'react'
import { graphql } from 'gatsby'
import BannerBlock from 'components/blocks/BannerBlock'
import FeaturedCollection from 'components/FeaturedCollection'
import CollectionsGridBlock from 'components/blocks/CollectionsGridBlock'
import VideoBlock from 'components/blocks/VideoBlock'

export default function HomePage({ data }) {
  const page = data.strapiHomePage
  const allCollections = data.allStrapiCollection.nodes
  const {
    introBannerBlock,
    bannerBlock2,
    featuredCollection,
    collectionsGridBlock,
    bannerBlock4,
    bannerBlock5,
    videoBlock,
  } = page
  return (
    <div className='page-brand-story'>
      <BannerBlock content={introBannerBlock} isHome={true} />
      <BannerBlock content={bannerBlock2} />
      <FeaturedCollection
        content={featuredCollection}
        allCollections={allCollections}
      />
      <CollectionsGridBlock
        content={collectionsGridBlock}
        allCollections={allCollections}
      />
      <BannerBlock content={bannerBlock4} />
      {/* <BannerBlock content={bannerBlock5} /> */}
      <VideoBlock content={videoBlock} />
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiHomePage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
      introBannerBlock: bannerBlock {
        pretitle
        title
        excerpt
        link
        alignH
        alignV
        textColor
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      bannerBlock2 {
        pretitle
        title
        excerpt
        link
        alignH
        alignV
        textColor
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      featuredCollection {
        featuredCollection {
          slug
        }
      }
      collectionsGridBlock {
        collections {
          title
          slug
        }
      }
      bannerBlock4 {
        pretitle
        title
        excerpt
        link
        alignH
        alignV
        textColor
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      bannerBlock5 {
        pretitle
        title
        excerpt
        link
        alignH
        alignV
        textColor
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      videoBlock {
        optimizedVideos {
          localFile {
            url
          }
        }
      }
    }
    allStrapiCollection(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        slug
        bannerBlock {
          pretitle
          title
          excerpt
          link
          alignH
          alignV
          textColor
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          link
        }
        featuredCollectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        products {
          title
          excerpt
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
