import React from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import LineLink from 'components/LineLink'
import Inview from 'components/Inview'
import OverlayItem from 'components/OverlayItem'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function CollectionsGridBlock({ content, allCollections }) {
  let { collections } = content

  collections.forEach((collection) => {
    const matchingAllCollection = allCollections.find(
      (allCollection) => allCollection.slug === collection.slug
    )
    collection.bannerBlock = matchingAllCollection.bannerBlock
  })
  return (
    <section className='collections-grid-block container pad-v-standard'>
      <div className='grid'>
        {collections.map((item, i) => {
          item.image = item.bannerBlock.image
          return (
            <OverlayItem
              key={i}
              content={item}
              version='collection'
              showLineLink={true}
            />
          )
        })}
      </div>
    </section>
  )
}

const Item = ({ content }) => {
  const { locale } = useLocalization()
  const { title, slug, bannerBlock } = content
  const link = `/collections/${slug}/`
  return (
    <Inview className='item fade-in up'>
      <LinkWrap to={link} className='block'>
        <div className='inner'>
          <div className='image'>
            <ImageWrap image={bannerBlock?.image} />
          </div>
          <div className='overlay'>
            <div className='bg' />
            <div className='text text-color-inverse'>
              <div className='wrap'>
                <h1>{title}</h1>
                <h4>
                  <LineLink content={{ text: tr('viewCollection', locale) }} />
                </h4>
              </div>
            </div>
          </div>
        </div>
      </LinkWrap>
    </Inview>
  )
}
